/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react'

const Pagination = ({current_page, total_pages, onPageNumClick}) => {
  const renderPage = (page_num) => (
    <li
      key={'page' + page_num}
      className={page_num === current_page ? 'active' : 'waves-effect'}>
      <a onClick={(e) => onPageNumClick(e, page_num)}>{page_num}</a>
    </li>
  )

  const renderSkip = () => <li key="skip">...</li>

  const renderPagination = () => {
    const cnt = Math.max(total_pages, 1)
    const current = current_page
    if (cnt < 6) {
      let result = [...Array(cnt).keys()].map((x) => renderPage(x + 1))
      return result
    }
    let pages = new Set()
    pages.add(1)
    if (current > 3) pages.add('... ')
    if (current - 1 > 0) pages.add(current - 1)

    pages.add(current)
    if (current + 1 < cnt) pages.add(current + 1)

    if (current + 2 < cnt) pages.add(' ...')
    pages.add(cnt)
    let pages_a = [...pages]

    let res = pages_a.map((x) => {
      if (x === ' ...' || x === '... ') return renderSkip()
      else return renderPage(x)
    })
    return res
  }

  return <ul className="pagination">{renderPagination()}</ul>
}
export default Pagination
