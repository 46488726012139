import {Tabs, Tab, TabPanel, TabList} from 'react-web-tabs'
import {Link, useNavigate} from 'react-router-dom'
import StarRatingComponent from 'react-star-rating-component'
import {useUser} from 'context/UserProvider'
import ProfileMainSection from 'components/ProfileMainSection'
import ProfileImageSection from 'components/ProfileImageSection'
import ProfileCarSection from 'components/ProfileCarSection'
import ProfileAccountSection from 'components/ProfileAccountSection/ProfileAccountSection'
import ProfilePayoutSection from 'components/ProfilePayoutSection'
import ProfileCardSection from 'components/ProfileCardSection'
import missingImg from 'images/missing.png'

import styles from './ProfiieMainInfo.module.scss'

const ProfileMainInfo = ({user}) => {
  const navigate = useNavigate()
  const {user: currentUser} = useUser()
  const {profile} = user.relationships || {}

  const displayImage = (user) => {
    const {profile} = user.relationships
    if (profile && profile.relationships) {
      const {images} = profile.relationships

      const img = images.find((img) => {
        return img?.attributes?.image_type === 'display'
      })
      return img ? img.attributes.url : missingImg
    }
  }

  const goToChat = (userId) => {
    if (currentUser?.id) {
      localStorage.setItem('directChatUserId', userId)
      navigate('/chat')
    } else {
      navigate('/login')
    }
  }

  const handleTab = (id) => {
    localStorage.setItem('currentTab', id)
  }

  return (
    <div className="profile-page">
      <div className="container">
        <div className="row">
          <div className="col l3 s12 center-align">
            <div className="user-img-container">
              <img
                src={displayImage(user)}
                className="user-img responsive-img"
                alt=""
              />
            </div>
            <div className="star-align">
              <StarRatingComponent
                name="average_rating"
                starCount={5}
                value={user.attributes.average_rating || 0}
                editing={false}
              />{' '}
              {!!user.attributes.rating_count &&
                user.attributes.rating_count !== 0 && (
                  <span>{`(${user.attributes.rating_count})`}</span>
                )}
            </div>
            <h5 className="mb20">{user.attributes.name}</h5>
            {user.id === currentUser?.id && (
              <Link to="/edit_profile" className="uploadImgLink">
                Edit Profile
              </Link>
            )}

            {user.id !== currentUser?.id && (
              <span
                className={styles.chatBtn}
                onClick={() => goToChat(user.id)}>
                <i className="fa fa-comments chat-icon" />
                {` `}
                Chat Now
              </span>
            )}
            <div className="panel-box">
              <div className="panel-header">Account Verifications</div>
              <div className="panel-item">
                Email
                {user.attributes.is_email_verified ? (
                  <i className="fa fa-check success"></i>
                ) : (
                  <i className="fa fa-times danger"></i>
                )}
              </div>
              <div className="panel-item">
                Facebook
                {user.attributes.facebook ? (
                  <i className="fa fa-check success"></i>
                ) : (
                  <i className="fa fa-times danger"></i>
                )}
              </div>
              <div className="panel-item">
                Google
                {user.attributes.google ? (
                  <i className="fa fa-check success"></i>
                ) : (
                  <i className="fa fa-times danger"></i>
                )}
              </div>
            </div>
          </div>
          <div className="col offset-l1 l8 s12">
            <div className="my-tablist">
              <Tabs defaultTab={'one'}>
                <TabList>
                  <Tab tabFor="one" onClick={() => handleTab('one')}>
                    Main
                  </Tab>
                  <Tab tabFor="two" onClick={() => handleTab('two')}>
                    Photos
                  </Tab>
                  <Tab tabFor="three" onClick={() => handleTab('three')}>
                    Car
                  </Tab>
                  {user?.id === currentUser?.id && (
                    <>
                      <Tab tabFor="four" onClick={() => handleTab('four')}>
                        Account
                      </Tab>

                      <Tab tabFor="five" onClick={() => handleTab('five')}>
                        Payout
                      </Tab>

                      <Tab tabFor="six" onClick={() => handleTab('six')}>
                        Payment Method
                      </Tab>
                    </>
                  )}
                </TabList>

                <TabPanel tabId="one">
                  <div className="mt20">
                    <ProfileMainSection profile={profile} user={user} />
                  </div>
                </TabPanel>
                <TabPanel tabId="two">
                  <div className="mt20">
                    <ProfileImageSection profile={profile} user={user} />
                  </div>
                </TabPanel>
                <TabPanel tabId="three">
                  <div className="mt20">
                    <ProfileCarSection profile={profile} user={user} />
                  </div>
                </TabPanel>
                {user.id === currentUser?.id && (
                  <>
                    <TabPanel tabId="four">
                      <div className="mt20">
                        <ProfileAccountSection />
                      </div>
                    </TabPanel>

                    <TabPanel tabId="five">
                      <div className="mt20">
                        <ProfilePayoutSection />
                      </div>
                    </TabPanel>

                    <TabPanel tabId="six">
                      <div className="mt20">
                        <ProfileCardSection />
                      </div>
                    </TabPanel>
                  </>
                )}
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfileMainInfo
