/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import Select from '@material-ui/core/Select'
import {confirmAlert} from 'react-confirm-alert'
import {PrimaryButton} from 'components/Buttons'
import useCardStore from 'store/CardStore'
import Cards from 'images/card.png'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {useNavigate} from 'react-router-dom'
import {useUser} from 'context/UserProvider'

const expMonth = [
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
]
const selectYear = ['2019', '2020', '2021', '2022', '2023', '2024']

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 300,
    },
  },
}

const ProfileCardSection = ({rideId}) => {
  const navigate = useNavigate()
  const {user, setUser} = useUser()
  const {
    cards,
    errors: cardErrors,
    isSaved: cardSaved,
    isDeleted: cardDeleted,
    isPrimary: cardPrimary,
    isProcessing,
    getCardsRequest,
    resetCardsFlagRequest,
    updateCardRequest,
    createCardRequest,
    setAsPrimaryCardRequest,
    deleteCardRequest,
    setCardProcessingRequest,
  } = useCardStore()

  const [card, setCard] = useState({})
  const [isPrimaryProcessing, setIsPrimaryProcessing] = useState(false)

  useEffect(() => {
    if (!!user) {
      getCardsRequest()
    }
  }, [getCardsRequest, user])

  useEffect(() => {
    if (cardSaved) {
      resetCardsFlagRequest()
      getCardsRequest()
      setCard({})
    }
  }, [cardSaved, getCardsRequest, resetCardsFlagRequest])

  useEffect(() => {
    if (cardDeleted) {
      resetCardsFlagRequest()
      getCardsRequest()
      setCard({})
    }
  }, [cardDeleted, getCardsRequest, resetCardsFlagRequest])

  useEffect(() => {
    if (cardPrimary) {
      resetCardsFlagRequest()
      getCardsRequest()
      setCard({})
    }
  }, [cardPrimary, getCardsRequest, resetCardsFlagRequest])

  const onFieldChange = (fieldName, event) => {
    setCard((c) => ({...c, [fieldName]: event.target.value}))
  }

  const errorMessageFor = (fieldName) => {
    if (cardErrors && cardErrors[fieldName]) return cardErrors[fieldName]
  }

  const handleCardSave = async () => {
    setCardProcessingRequest()
    const res = card.id
      ? await updateCardRequest(card.id, card)
      : await createCardRequest(card)

    if (!res.errors) {
      setUser((user) => ({
        ...user,
        attributes: {
          ...user.attributes,
          has_cards: true,
        },
      }))
    }

    if (rideId) {
      navigate(`/ride/${rideId}`)
    }
  }

  const handleEditCard = (cardToUpdate) => {
    const {last4, card_holder_name} = cardToUpdate.attributes
    const _card = {id: cardToUpdate.id, name: card_holder_name, last4}
    setCard(_card)
  }

  const setAsPrimaryCard = async (cardId) => {
    setIsPrimaryProcessing(true)
    await setAsPrimaryCardRequest(cardId)
    setIsPrimaryProcessing(false)
  }

  const handleDeleteCard = (cardId) => {
    confirmAlert({
      title: 'Alert!',
      message: 'Are you sure you want to delete the credit card details?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteCardRequest(cardId),
        },
        {
          label: 'No',
          onClick: () => console.log('canceled'),
        },
      ],
    })
  }

  return (
    <div className="profile-card-section">
      <div className="row">
        <div className="col s12 m12">
          <div className="row">
            <div className="col l5 m6 s12">
              <h5>
                Payment Method{' '}
                <span className="payout" target="_blank">
                  powered by <a href="https://stripe.com">Stripe</a>
                </span>
              </h5>
            </div>
            <div className="col l7 m6 s12">
              <img src={Cards} alt="visa" className="mt10 responsive-img" />
            </div>
          </div>
          <div className=" row">
            <div className="col s12">
              {!card.id && (
                <TextField
                  fullWidth
                  className="text-field cvc-field"
                  id="name"
                  type="text"
                  label="Name on the card*"
                  margin="normal"
                  value={card.name || ''}
                  onChange={(event) => onFieldChange('name', event)}
                />
              )}
              {!!card.id && (
                <TextField
                  fullWidth
                  className="text-field cvc-field"
                  id="name"
                  type="text"
                  label="Name on the card*"
                  margin="normal"
                  value={card.name || ''}
                />
              )}
              <span className="error">{errorMessageFor('name')}</span>
            </div>
          </div>
          <div className=" row">
            <div className="col s12">
              {!!card.id && (
                <TextField
                  fullWidth
                  className="text-field cvc-field"
                  id="card-number"
                  type="text"
                  label="Card Number*"
                  margin="normal"
                  value={`XXXX-XXXX-XXXX-${card.last4}`}
                />
              )}
              {!card.id && (
                <TextField
                  fullWidth
                  className="text-field cvc-field"
                  id="card-number"
                  type="text"
                  label="Card Number*"
                  margin="normal"
                  value={card.number || ''}
                  onChange={(event) => onFieldChange('number', event)}
                />
              )}
              <span className="error">{errorMessageFor('number')}</span>
            </div>
          </div>
          <div className="row">
            <div className="col l5 s12 mb10">
              <FormControl className="selectField">
                <InputLabel htmlFor="select-multiple">Select Month*</InputLabel>
                <Select
                  value={card.exp_month || ''}
                  onChange={(event) => onFieldChange('exp_month', event)}
                  input={<Input id="select-multiple" />}
                  MenuProps={MenuProps}
                  className="selected-menu-field">
                  {expMonth.map((month) => (
                    <MenuItem key={month} value={month}>
                      {month}
                    </MenuItem>
                  ))}
                </Select>
                <span className="error">{errorMessageFor('exp_month')}</span>
                {!!cardErrors['card_params'] && (
                  <span className="error">{cardErrors['card_params']}</span>
                )}
              </FormControl>
            </div>
            <div className="col l5 s12 mb10">
              <FormControl className="selectField">
                <InputLabel htmlFor="select-multiple">Select Year*</InputLabel>
                <Select
                  value={card.exp_year || ''}
                  onChange={(event) => onFieldChange('exp_year', event)}
                  input={<Input id="select-multiple" />}
                  MenuProps={MenuProps}
                  className="selected-menu-field">
                  {selectYear.map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
                <span className="error">{errorMessageFor('exp_year')}</span>
              </FormControl>
            </div>
            <div className="col l2 s12">
              <TextField
                fullWidth
                className="text-field cvc-field"
                id="cvc"
                type="text"
                label="CVC*"
                margin="normal"
                value={card.cvc || ''}
                onChange={(event) => onFieldChange('cvc', event)}
              />
              <span className="error">{errorMessageFor('cvc')}</span>
            </div>
          </div>

          <div className="mt20 mb20">
            <PrimaryButton
              color="primary"
              buttonName={isProcessing ? 'Please Wait...' : 'Save Card'}
              className="lg-primary"
              handleButtonClick={() => handleCardSave()}
              disabled={isProcessing}
            />
          </div>

          <div className="cardsList">
            {cards.map((card, index) => (
              <div className="list-group text-left" key={index}>
                <p className="cardNumber">
                  XXXX-XXXX-XXXX-{card.attributes.last4}
                  {card.attributes.is_primary && (
                    <span className="prime-card">Primary</span>
                  )}
                  {!card.attributes.is_primary && (
                    <a
                      onClick={() => handleDeleteCard(card.id)}
                      className="active-status">
                      <i className="fa fa-trash"></i>
                    </a>
                  )}
                  <a
                    onClick={() => handleEditCard(card)}
                    className="active-status">
                    <i className="fa fa-pencil"></i>&nbsp;
                  </a>
                  {!card.attributes.is_primary && (
                    <a
                      onClick={() => setAsPrimaryCard(card.id)}
                      className="prime-btn">
                      <span>
                        {isPrimaryProcessing
                          ? 'Please Wait...'
                          : 'Set as Primary'}
                      </span>{' '}
                      <i className="fa fa-credit-card" />
                    </a>
                  )}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfileCardSection
