import _ from 'underscore'
import React, {useMemo, useState} from 'react'
import Dropzone from 'react-dropzone'
import {confirmAlert} from 'react-confirm-alert'
import Lightbox from 'react-image-lightbox'
import ReactLoading from 'react-loading'
import {useUser} from 'context/UserProvider'
import useSessionStore from 'store/SessionStore'
import close from 'images/close.png'
import 'react-confirm-alert/src/react-confirm-alert.css'

const initial_state = {
  userId: null,
  lbOpen: false,
  photoIndex: 0,
}

const ProfileImageSection = ({profile, user}) => {
  const {user: currentUser, setUser} = useUser()
  const {
    isProcessing,
    setProcessingRequest,
    uploadProfileImageRequest,
    deleteProfileImageRequest,
  } = useSessionStore()

  const [state, setState] = useState(initial_state)

  const onCancel = () => {
    setState({
      ...state,
      files: [],
    })
  }

  const uploadImage = (files, imageType) => {
    setProcessingRequest()

    const fileObj = files[0]
    if (fileObj) {
      var FR = new FileReader()

      FR.addEventListener('load', async (e) => {
        const res = await uploadProfileImageRequest(imageType, e.target.result)
        if (res) {
          setUser(res.data)
        }
      })
      FR.readAsDataURL(fileObj)
    }
  }

  const profileImages = () => {
    const userProfile =
      user?.id === currentUser?.id
        ? currentUser?.relationships?.profile
        : profile
    const profileImages = userProfile?.relationships?.images?.filter(
      (img) =>
        img.attributes.image_type === 'profile' ||
        img.attributes.image_type === 'display',
    )

    return profileImages || []
  }

  const profileImagesArr = useMemo(() => {
    const arr = profileImages()
    return _.pluck(_.pluck(arr, 'attributes'), 'url')
  }, [profile])

  const deleteImage = (imageId) => {
    confirmAlert({
      title: 'Alert!',
      message: 'Are you sure you want to delete this image?',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            const res = await deleteProfileImageRequest(imageId)
            if (res) {
              setUser(res.data)
            }
          },
        },
        {
          label: 'No',
          onClick: () => console.log('canceled'),
        },
      ],
    })
  }

  const renderProfileThumbs = () => {
    const profile_images = profileImages()
    return profile_images.map((img, index) => (
      <div className="imgWrapper" key={index}>
        <img
          src={img.attributes.url}
          alt=""
          className="responsive-img uploadPic"
          onClick={() =>
            setState({
              ...state,
              lbOpen: true,
            })
          }
        />

        {user?.id === currentUser?.id &&
          img.attributes.image_type !== 'display' && (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a className="removeImg" onClick={() => deleteImage(img?.id)}>
              <img src={close} alt="" className="close-icon" />
            </a>
          )}
      </div>
    ))
  }

  const {lbOpen, photoIndex} = state

  return (
    <div className="profile-photo-section">
      <div className="car-profile-images">{renderProfileThumbs()}</div>
      <div className="bubble-container">
        {!!isProcessing && (
          <ReactLoading
            type="bubbles"
            color="#3399ff"
            height="100px"
            width="100px"
          />
        )}
      </div>
      {currentUser?.id === user?.id && (
        <div className="image image-dash mt40">
          <Dropzone
            onDrop={(files) => uploadImage(files, 'profile')}
            onFileDialogCancel={() => onCancel()}
            className="dropzone">
            {({getRootProps, getInputProps}) => (
              <section className="dropzone">
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div>
                    Try dropping image here, or click to select image to upload.
                  </div>
                </div>
              </section>
            )}
          </Dropzone>
        </div>
      )}
      {lbOpen && (
        <Lightbox
          mainSrc={profileImagesArr[photoIndex]}
          nextSrc={profileImagesArr[(photoIndex + 1) % profileImagesArr.length]}
          prevSrc={
            profileImagesArr[
              (photoIndex + profileImagesArr.length - 1) %
                profileImagesArr.length
            ]
          }
          onCloseRequest={() => setState({...state, lbOpen: false})}
          onMovePrevRequest={() =>
            setState({
              ...state,
              photoIndex:
                (photoIndex + profileImagesArr.length - 1) %
                profileImagesArr.length,
            })
          }
          onMoveNextRequest={() =>
            setState({
              ...state,
              photoIndex: (photoIndex + 1) % profileImagesArr.length,
            })
          }
        />
      )}
    </div>
  )
}

export default ProfileImageSection
