import React, {useState, useEffect, useCallback, useRef} from 'react'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Drawer from '@material-ui/core/Drawer'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Grow from '@material-ui/core/Grow'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuList from '@material-ui/core/MenuList'
import Icon from '@material-ui/core/Icon'
import Badge from '@material-ui/core/Badge'
import MenuIcon from '@material-ui/icons/Menu'

import logo from 'images/rs-logo.png'

import useNotificationStore from 'store/NotificationStore'
import useSessionStore from 'store/SessionStore'
import useTripRequestStore from 'store/TripRequestStore'
import {useUser} from 'context/UserProvider'
import {useActionCable} from 'context/ActionCableProvider'

const initial_state = {
  open: false,
  drawerOpen: false,
  notificationDropdown: false,
}

const Nav = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const {actionCable} = useActionCable()
  const {user: currentUser, setUser} = useUser()
  const notificationSubscription = useRef()

  const {notifications, getNotificationsRequest, updateNotificationRequest} =
    useNotificationStore()
  const {
    accessToken,
    notificationCount,
    chatsCount,
    logoutRequest,
    setNotificationCountRequest,
  } = useSessionStore()
  const {getTripRequestsRequest, getReceivedTripRequestsRequest} =
    useTripRequestStore()

  const [state, setState] = useState(initial_state)
  const [notiAnchorEl, setNotiAnchorEl] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)

  useEffect(() => {
    if (accessToken) {
      getNotificationsRequest()
    }
  }, [accessToken, getNotificationsRequest])

  const subscribeChannel = useCallback(() => {
    notificationSubscription.current = actionCable?.subscriptions.create(
      {channel: 'NotificationsChannel'},
      {
        connected: () => {
          console.log('notification channel connected')
        },
        disconnected: () => {
          console.log('notification channel disconnected')
        },
        received: function (data) {
          if (data.user_id === currentUser?.id) {
            setNotificationCountRequest(data)

            getReceivedTripRequestsRequest()
            getTripRequestsRequest()
          }
        },
      },
    )
  }, [
    actionCable?.subscriptions,
    currentUser?.id,
    getReceivedTripRequestsRequest,
    getTripRequestsRequest,
    setNotificationCountRequest,
  ])

  useEffect(() => {
    if (actionCable) {
      subscribeChannel()
    }

    return () => {
      actionCable?.subscriptions?.remove(notificationSubscription.current)
    }
  }, [actionCable, subscribeChannel])

  const toggleDrawer = () => {
    setState((state) => ({drawerOpen: !state.drawerOpen}))
  }

  const handleToggle = () => {
    setState((state) => ({open: !state.open}))
  }

  const handleNotificationToggle = () => {
    getNotificationsRequest()
    setState((state) => ({notificationDropdown: !state.notificationDropdown}))
  }

  const handleClose = (event) => {
    if (anchorEl.contains(event.target)) {
      return
    }
    setState({
      ...state,
      open: false,
    })
  }

  const handleNotiClose = (event) => {
    if (notiAnchorEl.contains(event.target)) {
      return
    }
    setState({
      ...state,
      notificationDropdown: false,
    })
  }

  const handleNotificationClick = (notification) => {
    const {
      subject_type,
      for_driver,
      review_total,
      review_rated_to,
      subject_id,
    } = notification.attributes

    if (!notification.attributes.is_read) {
      updateNotificationRequest(notification.id)
    }

    if (subject_type === 'TripRequest') {
      if (for_driver) {
        navigate('/requests')
      } else {
        navigate('/my_rides')
      }
    } else {
      if (subject_type === 'Review') {
        if (review_total) {
          if (review_rated_to === currentUser?.id) {
            navigate('/my_profile')
          } else {
            navigate(`/profile/${review_rated_to}`)
          }
        } else {
          navigate(`/reviews/${subject_id}`)
        }
      }
    }
    setState({
      ...state,
      notificationDropdown: false,
    })
  }

  const handleMenuClick = (url) => {
    navigate(url)
    setState({
      ...state,
      open: false,
      notificationDropdown: false,
    })
  }

  const handleLogout = async () => {
    await logoutRequest()
    setUser(null)
    navigate('/login')
  }

  const renderNotifications = () => {
    if (notifications.length > 0) {
      return notifications.map((noti, index) => (
        <div
          key={index}
          className={`notification ${noti.attributes.is_read ? '' : 'unread'}`}
          onClick={() => handleNotificationClick(noti)}>
          {noti.attributes.description}
          <div className="time">{noti.attributes.received_at} ago</div>
        </div>
      ))
    } else {
      return (
        <div className="no-notification" onClick={handleNotiClose}>
          {' '}
          No unread notifications{' '}
        </div>
      )
    }
  }

  const goToLogin = () => {
    localStorage.setItem('prevUrl', `/new_ride`)
    navigate('/login')
  }
  const {open, notificationDropdown} = state

  return (
    <div className="nav-container">
      <AppBar position="fixed" className="home-nav">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={toggleDrawer}
            className="hide-on-med-and-up menu-btn">
            <MenuIcon />
          </IconButton>
          <div className="logo-container">
            <Link id="main-logo" to="/">
              <img className="responsive-img logo" src={logo} alt="" />
            </Link>
          </div>
          {location.pathname !== '/search' && (
            <Link
              to="/search"
              className="nav-search-btn"
              onClick={() => {
                localStorage.setItem('search_refresh', '1')
              }}>
              <Typography
                variant="body2"
                color="inherit"
                className="hide-on-small-only left-links">
                <i className="fa fa-search  nav-icon"></i> Search
              </Typography>
            </Link>
          )}
          <Typography
            color="inherit"
            className="flex partner-name"></Typography>
          {currentUser?.id && (
            <div style={{display: 'inline-flex'}}>
              <Link to="/new_ride" style={{alignSelf: 'center'}}>
                <Typography
                  variant="body2"
                  color="inherit"
                  className="left-links hide-on-small-only create-btn">
                  Create a Ride
                </Typography>
              </Link>
              <Link to="/safety" style={{alignSelf: 'center'}}>
                <Typography
                  variant="body2"
                  color="inherit"
                  className=" hide-on-med-and-down left-links">
                  Trust & Safety
                </Typography>
              </Link>
              <div style={{position: 'inherite'}}>
                <IconButton
                  ref={(notiNode) => {
                    setNotiAnchorEl(notiNode)
                  }}
                  aria-owns={
                    notificationDropdown ? 'notification-list' : undefined
                  }
                  aria-haspopup="true"
                  onClick={handleNotificationToggle}
                  className="noti-icon">
                  {!!notificationCount && notificationCount > 0 && (
                    <Badge
                      className="badge"
                      badgeContent={notificationCount}
                      color="primary">
                      <i className="fa fa-bell nav-icon" />
                    </Badge>
                  )}
                  {(!notificationCount || notificationCount === 0) && (
                    <i className="fa fa-bell nav-icon" />
                  )}
                </IconButton>
                <Popper
                  open={!!notificationDropdown}
                  anchorEl={notiAnchorEl}
                  transition
                  disablePortal
                  placement={'bottom-end'}>
                  {({TransitionProps, placement}) => (
                    <Grow
                      {...TransitionProps}
                      id="notification-list"
                      style={{
                        transformOrigin:
                          placement === 'bottom'
                            ? 'center top'
                            : 'center bottom',
                      }}>
                      <Paper>
                        <ClickAwayListener onClickAway={handleNotiClose}>
                          <div className="notification-container">
                            {renderNotifications()}
                          </div>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
              <Link to="/chatList" style={{alignSelf: 'center'}}>
                {!!chatsCount && chatsCount > 0 && (
                  <Badge
                    className="badge"
                    badgeContent={chatsCount}
                    color="primary">
                    <i className="fa fa-comments nav-icon" />
                  </Badge>
                )}
                {(!chatsCount || chatsCount === 0) && (
                  <i className="fa fa-comments nav-icon" />
                )}
              </Link>
              {/* eslint-disable-next-line */}
              <IconButton
                ref={(node) => {
                  setAnchorEl(node)
                }}
                aria-owns={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                className="dropdown hide-on-small-only">
                <Typography
                  variant="body2"
                  color="inherit"
                  className="left-links">
                  {currentUser?.attributes?.name}
                </Typography>
              </IconButton>
              <Popper
                open={!!open}
                anchorEl={anchorEl}
                transition
                disablePortal>
                {({TransitionProps, placement}) => (
                  <Grow
                    {...TransitionProps}
                    id="menu-list-grow"
                    style={{
                      transformOrigin:
                        placement === 'bottom' ? 'center top' : 'center bottom',
                    }}>
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList>
                          <MenuItem
                            onClick={() => handleMenuClick(`/my_profile`)}>
                            <Icon className="menu-icon">account_circle</Icon>{' '}
                            Profile
                          </MenuItem>
                          <MenuItem
                            onClick={() => handleMenuClick(`/requests`)}>
                            <Icon className="menu-icon">mail</Icon>Requests
                          </MenuItem>
                          <MenuItem
                            onClick={() => handleMenuClick(`/my_rides`)}>
                            <Icon className="menu-icon">directions_car</Icon>My
                            Rides
                          </MenuItem>
                          <MenuItem onClick={() => handleMenuClick(`/reviews`)}>
                            <Icon className="menu-icon">rate_review</Icon>
                            Reviews
                          </MenuItem>
                          <MenuItem onClick={handleLogout}>
                            <Icon className="menu-icon">logout</Icon>Logout
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
          )}
          {!currentUser?.id && (
            <div style={{display: 'inline-flex'}}>
              {/* eslint-disable-next-line */}
              <a
                to="#"
                style={{alignSelf: 'center'}}
                onClick={() => goToLogin()}>
                <Typography
                  variant="body2"
                  color="inherit"
                  className="left-links hide-on-small-only create-btn">
                  Create a Ride
                </Typography>
              </a>
              <Link to="/safety" style={{alignSelf: 'center'}}>
                <Typography
                  variant="body2"
                  color="inherit"
                  className=" hide-on-med-and-down left-links">
                  Trust & Safety
                </Typography>
              </Link>
              <Link to="/signup" style={{alignSelf: 'center'}}>
                <Typography
                  variant="body2"
                  color="inherit"
                  className="hide-on-small-only left-links ml15">
                  Sign Up
                </Typography>
              </Link>
              <Link to="/login" style={{alignSelf: 'center'}}>
                <Typography
                  variant="body2"
                  color="inherit"
                  className="hide-on-small-only left-links ml15">
                  Login
                </Typography>
              </Link>
            </div>
          )}
        </Toolbar>
      </AppBar>
      <Drawer open={state.drawerOpen} onClose={toggleDrawer}>
        {!!currentUser?.id && (
          <div className="drawer-container">
            <div className="user-name">Hi {currentUser?.attributes?.name}</div>

            {location.pathname !== '/search' && (
              <Link
                to="/search"
                onClick={() => {
                  localStorage.setItem('search_refresh', '1')
                  toggleDrawer()
                }}
                className="nav-search-btn">
                <div className="left-links">
                  <i className="fa fa-search  nav-icon" /> Search
                </div>
              </Link>
            )}
            <Link to="/new_ride" onClick={toggleDrawer}>
              <div className="create-btn">Create a Ride</div>
            </Link>
            <Link to="/safety" onClick={toggleDrawer}>
              <div className="left-links">
                <i className="fa fa-handshake-o nav-icon" /> Trust & Safety
              </div>
            </Link>
            <Link to="/my_profile" onClick={toggleDrawer}>
              <div className="left-links">
                <i className="fa fa-user nav-icon" />
                Profile
              </div>
            </Link>
            <Link to="/requests" onClick={toggleDrawer}>
              <div className="left-links">
                <i className="fa fa-envelope nav-icon" /> Requests
              </div>
            </Link>
            <Link to="/my_rides" onClick={toggleDrawer}>
              <div className="left-links">
                <i className="fa fa-car nav-icon" /> My Rides
              </div>
            </Link>
            <Link to="/reviews" onClick={toggleDrawer}>
              <div className="left-links">
                <i className="fa fa-pencil-square-o nav-icon" /> Reviews
              </div>
            </Link>
            <Link to="/" onClick={handleLogout}>
              <div className="left-links">
                <i className="fa fa-sign-out nav-icon" /> Logout
              </div>
            </Link>
          </div>
        )}
        {!currentUser?.id && (
          <div className="drawer-container">
            <Link
              to="/search"
              className="nav-search-btn"
              onClick={() => {
                localStorage.setItem('search_refresh', '1')
                toggleDrawer()
              }}>
              <div className="left-links">
                <i className="fa fa-search  nav-icon" /> Search
              </div>
            </Link>
            <Link to="/login" onClick={toggleDrawer}>
              <div className="create-btn">Create a Ride</div>
            </Link>
            <Link to="/safety" onClick={toggleDrawer}>
              <div className="left-links">
                <i className="fa fa-handshake-o nav-icon" /> Trust & Safety
              </div>
            </Link>
            <Link to="/signup" onClick={toggleDrawer}>
              <div className="left-links">
                <i className="fa fa-street-view nav-icon" /> Sign Up
              </div>
            </Link>
            <Link to="/login" onClick={toggleDrawer}>
              <div className="left-links">
                <i className="fa fa-sign-in nav-icon" /> Login
              </div>
            </Link>
          </div>
        )}
      </Drawer>
    </div>
  )
}

export default Nav
